@tailwind base;
@tailwind components;
@tailwind utilities;

.react-select__control {
  @apply relative w-full cursor-default
    rounded-md
    shadow-sm
    border
    border-gray-300
    mt-1
    bg-white
    text-left
    sm:text-sm
    dark:bg-slate-700
    !important;
}

.react-select--is-invalid .react-select__control {
  @apply border-red-300 !important;
}

.react-select__control--is-focused {
  @apply border-indigo-500 ring-1 ring-indigo-500 dark:border-green-500 dark:ring-green-500 !important;
}

.react-select--is-invalid .react-select__control--is-focused {
  @apply border-red-500 ring-1 ring-red-500 !important;
}

.react-select__input {
  @apply ring-0 dark:text-white !important;
}

.react-select__single-value {
  @apply text-gray-900 dark:text-white !important;
}
